/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import { toValue } from 'vue'
import PageHasNoEngagementURLParametersException
  from '~/src/Domain/Engagement/Exception/PageHasNoEngagementURLParametersException'
import OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationActivator
  from '~/src/UserInterface/Organisation/composables/useOrganisationActivator'
import useOrganisationApiRepository
  from '~/src/UserInterface/Organisation/composables/useOrganisationApiRepository'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

/**
 * This middleware must be called on every page
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a regular user
 * - The organisation state must be populated
 *
 * This middleware checks if the user is registered to an organisation:
 * - If the user is registered to an organisation, the process continues
 * - If the user is not registered to an organisation, redirect the user to their profile page
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[ORGANISATION] Middleware fired')

  if (typeof to.params.organisationId !== 'string') {
    throw new PageHasNoEngagementURLParametersException(
      `Current page "${to.fullPath}" has no organisationId in URL params.`,
    )
  }

  const nuxtApp = useNuxtApp()
  const { getUser } = useUserState()
  const user = getUser()

  const uuidEncoder = useService('uuidEncoder')
  const decodedOrganisationId = uuidEncoder.decode(to.params.organisationId)

  const { hasOrganisation, getOrganisation } = useOrganisationState()
  if (hasOrganisation.value) {
    if (toValue(getOrganisation())['@id'].toId() !== decodedOrganisationId && user.isAdmin()) {
      logger.info('[ORGANISATION] Admin user has requested a different organisation')
      const organisationApiRepository = useOrganisationApiRepository()
      const organisation = await organisationApiRepository.find(OrganisationIri.fromId(decodedOrganisationId))

      const organisationActivator = useOrganisationActivator()
      await organisationActivator.activate(organisation)

      return
    }

    logger.info('[ORGANISATION] User has organisation')

    return
  }

  logger.info('[ORGANISATION] User has no organisation')

  const userId = uuidEncoder.encode(user['@id'].toId())
  const userSlug = user.getSlug().toString()

  logger.info('[ORGANISATION] Redirect user to profile page')

  return navigateTo(
    // eslint-disable-next-line ts/no-unsafe-argument
    nuxtApp.$localePath({
      name: 'settings-profile-Profile',
      params: {
        userId,
        userSlug,
      },
    }),
  )
})
